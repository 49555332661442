import React, { useState, Suspense } from "react";

// @material
import { Box, Stack } from "@mui/material";

// components
import Page from "@components/Page";
import LoadingScreen from '@components/LoadingScreen';


import AssetTable from "./AssetListViewer/AssetTable";
import DataHeader from "./Header/Title";
import DataTabs from "./Tabs";
import { SideBar } from '@pages/asset/comparison/SideBar';
import { PageTitle } from "@components/ui/styles";
// ----------------------------------------------------------------------

const filterFunction = (asset: string[]) : string[] => {
  return asset.map( v=> `SU-${v}`)
}

function OperationsPage() {
  const [checkedAssets, setCheckedAsset] = useState<Array<Object>>([]);

  return (
    <Page title="미국 주식 종목 탐색">
      <Stack spacing={2}>
        <PageTitle>미국 주식 종목 탐색</PageTitle>
        <Stack direction="row" sx={{ pl: -10 }}>
          <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
              <Suspense fallback={<LoadingScreen />}>
                <AssetTable
                  onSelectAsset={(select) => {
                    const updateAsset = [select].concat(checkedAssets);
                    setCheckedAsset(updateAsset);
                  }}
                />
              </Suspense>
              <Suspense fallback={<LoadingScreen />}>
                <DataHeader />
                <DataTabs />
              </Suspense>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <SideBar
        assetType="stock"
        market="us"
        checkedAssets={checkedAssets.map((v) => {
          return { ...v, name: v["종목명"] };
        })}
        onChange={setCheckedAsset}
        assetFilter={filterFunction}
      />
    </Page>
  );
}

export default React.memo(OperationsPage);
