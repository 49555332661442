import React, { useEffect, useState } from "react";
import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { selectedTickerState, operationListState} from "@states/stockUs";
import { STOCKUS } from "@pages/asset/define";
import useSearchHook from "../../../detail/useSearchHook";
import { Toolbar } from "@pages/asset/detail/Toolbar";
import { Grid } from "@mui/material";

type Props = {
  onChangeChecked?: (e) => void;
  onSelectAsset?: Function;
};

function OperationTable({ onChangeChecked, onSelectAsset }: Props) {
  
  const setSelectedTicker = useSetRecoilState(selectedTickerState);
  const stockUsOperations = useRecoilValue(operationListState);
  
  const [searchText, setSearch] = useState("");
  const [gridData, setGridData] = useState(stockUsOperations);

  const [updateSearch] = useSearchHook(
    stockUsOperations,
    setSearch,
    setGridData
  );

  useEffect(()=>{
    setGridData(stockUsOperations)
  }, [stockUsOperations])
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Toolbar text={searchText} onChangeText={updateSearch} />
        </Grid>
        <Grid item xs={12} md={8}>
          {/* <OperationDownload /> */}
        </Grid>
      </Grid>
      <AssetInformationGrid
        market={"STOCKUS"}
        onSelectAsset={(asset) => {
          setSelectedTicker(asset);
          if (onSelectAsset) {
            onSelectAsset(asset);
          }
        }}
        columns={STOCKUS}
        data={gridData}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export default OperationTable;
