import { reducePriceValue } from "@api/asset";
import { atom, selector } from "recoil";
import { AssetType } from "types/common";
import axios from "../utils/axios";

export const ALLCOUNTRY = "전체";

export const valueState = atom({
  key: "stockUSValueState",
  default: 0,
});

export const analysisDataState = atom({
  key: "stockUSAnalysisDataState",
  default: { load: false },
});

export const stockUSChartDateState = atom({
  key: "stockUSChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const selectedTickerState = atom<AssetType | null>({
  key: "stockUSSelectedTickerState",
  default: null,
});

export const operationListState = selector({
  key: "stockUSOperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`data/asset/stockus/`);
    return response.data.data;
  },
});

export const simpleOperationListState = selector({
  key: "stockUSSimpleOperationListState",
  get: async ({ get }) => {
    const opList = await get(operationListState);
    const convert = opList.map((value) => (
      {
        rf_id: value.rf_id,
        ticker: value.ticker,
        product_name: value["종목명"],
        label: value.ticker + " - " + value["종목명"],
      }
    ));
    return convert;
  },
});

export const artificailIndexDataState = selector({
  key: "stockUSArtificailIndexDataState",
  get: async ({ get }) => {
    try {
      const selected = get(selectedTickerState);
      if (selected === null) return;
      const { ticker } = selected;
      const dataParam = get(stockUSChartDateState);
      const response = await axios.get(
        `data/asset/stockus/${ticker}/historical`,
        { params: dataParam }
      );
      const responseObj = response.data.data;
      return reducePriceValue(responseObj);
    } catch (e) {}
  },
});
