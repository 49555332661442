import { useRecoilValue } from "recoil";
import { selectedTickerState } from "@states/stockUs";
import { AssetTitle } from "@pages/asset/styles"
// import AssetMemo from '../../../detail/AssetMemo';

function Header({name="종목명"}) {
  const selectedTicker = useRecoilValue(selectedTickerState);
  return (
    selectedTicker && (
      <>
        <AssetTitle
          ticker={selectedTicker.ticker}
          name={selectedTicker[name]}
        />
        {/* <AssetMemo rf_id={selectedTicker.rf_id} memo={selectedTicker["memo"]} /> */}
      </>
    )
  );
}

export default Header;
