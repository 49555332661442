import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import {
  artificailIndexDataState,
} from "@states/stockUs";
import { useOpenSnackbar } from "@hooks/useSnackbar";

import PriceLineChart from "@components/chart/PriceLineChart";
import { CURRENT_YEAR } from "@components/tab/PeriodTab/define";

function UndlPanel() {
  const artificailIndexData = useRecoilValue(artificailIndexDataState);

  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    if (!artificailIndexData) {
      openSnackbar("데이터가 없습니다. 😰", "error");
    } else return;
  }, [artificailIndexData, openSnackbar]);

  if (!artificailIndexData) return <></>;

  const { chartBaseDates, chartData } = artificailIndexData;

  const options = {
    chart: {
      id: "chart#1",
      animations: {
        enabled: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      sparkline: {
        enabled: false,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: Math.max(new Date(chartBaseDates[0]).getTime(), new Date(`${CURRENT_YEAR}.01.01`).getTime()),
          max: new Date(chartBaseDates[chartBaseDates.length - 1]).getTime(),
        },
      },
    },
    stroke: {
      width: 2,
    },
  };
  return (
    <>
      <PriceLineChart
        index={chartBaseDates}
        data={chartData}
        options={options}
        showNavigator
        height={400}
      />
    </>
  );
}

export default UndlPanel;
